import { readState } from "@/__main__/app-state.mjs";
import getData from "@/__main__/get-data.mjs";
import * as API from "@/game-lol/apis/api.mjs";
import { SERVICES_TO_REGIONS } from "@/game-lol/constants/constants.mjs";
import LCUSummoner from "@/game-lol/models/lol-summoner-lcu.mjs";
import isLinkedSummoner from "@/game-lol/utils/is-linked-summoner.mjs";
import {
  getLCUAvailable,
  getLCUSummonerProfile,
} from "@/game-lol/utils/lol-client-api.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import {
  extractRegionFromDerivedId,
  getDerivedId,
  isPBE,
  separateDerivedRiotID,
} from "@/game-lol/utils/util.mjs";
import deepMerge from "@/util/deep-merge.mjs";
import { devError } from "@/util/dev.mjs";

async function fetchData(params, searchParams, state) {
  const [region, name] = params;
  const queue = searchParams.get("queue") || "RANKED_SOLO_5X5";

  if (isPBE(region)) return;

  if (!region || !name) {
    devError("Missing region or name.");
    return;
  }

  const _isUpdate = Boolean(state?.isUpdate);
  const derivedId = getDerivedId(region, name);
  const [gameName, tagLine] = separateDerivedRiotID(name);

  const currentSummoner = readState.volatile.currentSummoner;
  const currentRegion =
    currentSummoner && extractRegionFromDerivedId(currentSummoner);

  const isUnsupportedRegion = !SERVICES_TO_REGIONS[region.toLowerCase()];

  const useLCU =
    isUnsupportedRegion && currentRegion === region && getLCUAvailable();

  if (isUnsupportedRegion && !useLCU) {
    devError("Can not retrieve profile info by any means.");
    return;
  }

  // Extend expiry time for own accounts.
  const isOwnAccount = isLinkedSummoner(readState, region, name);
  const expiryTime = !isOwnAccount
    ? null
    : Date.now() + 1000 * 60 * 60 * 24 * 365; // 1 year

  const profileOptions = {
    expiryTime,
    mergeFn: deepMerge,
  };
  const profilePath = ["lol", "profiles", derivedId];
  const profile = isUnsupportedRegion
    ? await getData(
        () => getLCUSummonerProfile(name),
        LCUSummoner,
        profilePath,
        profileOptions,
      )
    : await API.PLAYER.getProfile({ region, gameName, tagLine });

  if (!profile || profile instanceof Error) return;

  API.PLAYER.getChampionAggregatesPreSeed_DEPRECATE_SOON({
    region: region.toUpperCase(),
    gameName,
    tagLine,
    queue,
    options: { shouldSkipDBRead: true },
    path: ["volatile", `${gameName}_${tagLine}`],
  }).catch(devError);

  const promises = [];

  if (!isUnsupportedRegion) {
    promises.push(
      API.PLAYER.getChampionAggregates({
        region: region?.toUpperCase(),
        gameName,
        tagLine,
        profileId: profile.id,
        queue: getQueueDetails(queue).gql,
      }),
    );
  }

  return Promise.all(promises);
}

export default fetchData;
